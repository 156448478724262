<template>
  <div style="min-height: 100vh">
    <div id="projects"></div>
    <div
      ref="allPostElement"
      v-if="allPosts"
      class="grouped-posts"
      :class="{ inView: isVisible }"
    >
      <template
        v-for="(post, idx) in allPosts.data"
        :key="post.attributes?.title + '_' + post.id + '_' + idx"
      >
        <PostCard
          v-if="
            post.attributes &&
            (selectedSlug ===
              post.attributes.category?.data?.attributes?.slug ||
              !selectedSlug)
          "
          :attributes="post.attributes"
        />
      </template>
    </div>
    <PostFilters
      v-if="allCategories"
      @update:slug="updateSlug"
      :filters="allCategories"
      :isVisible="isVisible"
    />
  </div>
</template>

<script setup lang="ts">
// GraphQL
const { client } = useApolloClient();
import { GET_ALL_POSTS } from "@/gql/AllPosts";

// Types
import type {
  Query,
  PostEntityResponseCollection,
  PostCategoryEntityResponseCollection,
} from "@/types/Strapi";

// i18n / Language
const { t } = useI18n();
const locale = ref(useI18n().locale);
const { allTranslations } = storeToRefs(useTranslationsStore());

// Auth/Preview mode
const { previewMode } = storeToRefs(usePreviewModeStore());

// Helper function to translate css calc experssion to an actual pixel value
const convertToPixels = (value: string) => {
  const tempElement = document.createElement("div");
  tempElement.style.position = "absolute";
  tempElement.style.visibility = "hidden";
  tempElement.style.height = value;
  document.body.appendChild(tempElement);
  const pixels = window.getComputedStyle(tempElement).height;
  document.body.removeChild(tempElement);
  return pixels;
};

// Display filters when posts are visible
const allPostElement = ref(null);
const isVisible = ref(false);

onMounted(() => {
  const headerHeightVar = useCssVar("--header-h");
  const headerHeight = convertToPixels(headerHeightVar.value);
  const rootMarginValue = `0px 0px -${headerHeight} 0px`;

  const { stop } = useIntersectionObserver(
    allPostElement,
    ([{ isIntersecting }], observerElement) => {
      isVisible.value = isIntersecting;
    },
    {
      rootMargin: rootMarginValue,
    },
  );
});

// Filter post by category
const selectedSlug = ref<string | null>(null);

const updateSlug = (slug: string) => {
  selectedSlug.value = slug;
};

////// Fetch all posts from strapi :

// Query variables
const allPosts = ref<PostEntityResponseCollection>();
const allCategories = ref<PostCategoryEntityResponseCollection>();

const handleQuery = (data: Query | null, error: any) => {
  try {
    // Graphql error + nothing found
    if (
      error ||
      data?.posts?.data.length === 0 ||
      data?.postCategories?.data.length === 0
    ) {
      throw error;
    } else {
      // Get posts data
      allPosts.value = data?.posts as PostEntityResponseCollection;
      // Get categories
      allCategories.value =
        data?.postCategories as PostCategoryEntityResponseCollection;
    }
  } catch (error: any) {
    // Throw error pretty-formated for error handler (error.vue), default is service unavailable
    console.error(error);
    throw createError({
      statusCode: error?.statusCode ?? 503,
      statusMessage:
        error?.message ?? allTranslations.value.error_503 ?? t("error_503"),
      stack: error?.stack ?? "",
      fatal: true,
    });
  }
};

// Fetch on setup
const { data, error } = await useAsyncQuery<Query>(GET_ALL_POSTS, {
  locale: locale.value,
  publicationState: previewMode.value ? "PREVIEW" : "LIVE",
});
handleQuery(data.value, error.value);

// Watch for locale and preview mode changes to refetch data
watch(
  () => [locale.value, previewMode.value],
  async () => {
    const { data, error } = await client.query({
      query: GET_ALL_POSTS,
      variables: {
        locale: locale.value,
        publicationState: previewMode.value ? "PREVIEW" : "LIVE",
      },
    });
    handleQuery(data, error);
  },
);
</script>
