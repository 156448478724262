<template>
  <div class="home-gallery" v-if="pagesImages">
    <section
      id="desktop-wrapper"
      class="hidden h-screen sm:flex"
      @click="scrollTrigger"
    >
      <NuxtImg
        v-for="(image, idx) in pagesImages"
        class="gallery-image"
        :class="`gallery-image-${idx}` + `${idx === 0 ? ' active' : ''}`"
        :key="image.id + '_' + idx"
        sizes="sm:600px md:100vw"
        :src="image.attributes?.image_desktop.data?.attributes?.url"
        alt=""
        :loading="idx === 0 ? 'eager' : 'lazy'"
      />
    </section>

    <section
      id="mobile-wrapper"
      class="flex h-screen sm:hidden"
      @click="scrollTrigger"
    >
      <NuxtImg
        v-for="(image, idx) in pagesImages"
        class="gallery-image"
        :class="`gallery-image-${idx}` + `${idx === 0 ? ' active' : ''}`"
        :key="image.id + '_' + idx"
        sizes="sm:600px md:100vw"
        :src="
          image.attributes?.image_mobile?.data?.attributes?.url ??
          image.attributes?.image_desktop.data?.attributes?.url
        "
        alt=""
        :loading="idx === 0 ? 'eager' : 'lazy'"
      />
    </section>
  </div>
</template>

<script setup lang="ts">
// GraphQL
const { client } = useApolloClient();
import { GET_HOMEPAGE } from "@/gql/Homepage";

// Types
import type { Query, HomeGalleryImageEntity } from "@/types/Strapi";

// Utils
import debounce from "~/assets/js/debounce";

// Auth/Preview mode
const { previewMode } = storeToRefs(usePreviewModeStore());

// i18n / Language
const { t } = useI18n();
const locale = ref(useI18n().locale);
const { allTranslations } = storeToRefs(useTranslationsStore());

// Set meta tags with main ones from pinia
setMeta(true);

// Scroll to mosaïque
const scrollToPosts = () => {
  const projects = document.querySelector("#projects");
  if (projects) {
    projects.scrollIntoView({ behavior: "smooth" });
  }
};

const scrollTrigger = async () => {
  debounce(scrollToPosts(), 500);
};

// Scroll based on query params
const route = useRoute();
const router = useRouter();

const scrollOnQuery = () => {
  const { projects } = route.query;
  if (projects) {
    router.replace({ query: {} });
    setTimeout(() => {
      scrollToPosts();
    }, 250);
  }
};

onMounted(() => {
  scrollOnQuery();
});

watch(
  () => route.query.projects,
  () => {
    scrollOnQuery();
  },
);

////// Fetch homepage data :

const pagesImages = ref<Array<HomeGalleryImageEntity>>();
const backgroundImages = ref({
  idx: 0,
  maxLength: 0,
});

const handleQuery = (data: Query | null, error: any) => {
  try {
    // Graphql error
    if (error) {
      throw error;
    }

    if (data?.homeGalleryImages?.data.length === 0) {
      return;
    }

    // Get background images
    pagesImages.value = data?.homeGalleryImages
      ?.data as HomeGalleryImageEntity[];

    // Get images array length
    backgroundImages.value.maxLength = pagesImages.value.length;
  } catch (error: any) {
    // Throw error pretty-formated for error handler (error.vue), default is service unavailable
    console.error(error);
    throw createError({
      statusCode: error?.statusCode ?? 503,
      statusMessage:
        error?.message ?? allTranslations.value.error_503 ?? t("error_503"),
      stack: error?.stack ?? "",
      fatal: true,
    });
  }
};

// Fetch on setup
const { data, error } = await useAsyncQuery<Query>(GET_HOMEPAGE, {
  locale: locale.value,
  publicationState: previewMode.value ? "PREVIEW" : "LIVE",
});
handleQuery(data.value, error.value);

// Watch for locale changes to translate page
watch(
  () => [locale.value, previewMode.value],
  async () => {
    const { data, error } = await client.query({
      query: GET_HOMEPAGE,
      variables: {
        locale: locale.value,
        publicationState: previewMode.value ? "PREVIEW" : "LIVE",
      },
    });
    handleQuery(data, error);
  },
);

////// Background images transitions :

let intervalId: ReturnType<typeof setTimeout>;

const updateBackgroundImages = () => {
  if (pagesImages.value) {
    // Increment index
    backgroundImages.value.idx = backgroundImages.value.idx + 1;

    // Current image
    const currentImage = document.getElementsByClassName(
      `gallery-image-${backgroundImages.value.idx - 1}`,
    );

    Array.from(currentImage).forEach((element) => {
      element.classList.remove("active");
    });

    // Check if reached end of array
    if (backgroundImages.value.idx >= backgroundImages.value.maxLength) {
      // Reset index
      backgroundImages.value.idx = 0;
    }

    // Next image
    const nextImage = document.getElementsByClassName(
      `gallery-image-${backgroundImages.value.idx}`,
    );

    Array.from(nextImage).forEach((element) => {
      element.classList.add("active");
    });
  }
};

// Background images timer
onMounted(() => {
  intervalId = setInterval(() => {
    updateBackgroundImages();
  }, 3000);
});

onUnmounted(() => clearInterval(intervalId));
</script>
