<template>
  <NuxtLink
    :data-category="attributes?.categories?.data[0].attributes?.slug"
    :to="localPath(`/projet/${attributes?.slug}`)"
    class="post-wrapper"
  >
    <div class="image-wrapper">
      <NuxtImg
        sizes="sm:100vw md:33vw"
        :src="attributes?.image?.data?.attributes?.url"
        alt=""
        loading="lazy"
      />
    </div>

    <div class="title-wrapper">
      <div class="post-title">
        <h6>{{ attributes?.title }}</h6>
        <span>{{ attributes?.subtitle }}</span>
      </div>
    </div>
  </NuxtLink>
</template>

<script setup lang="ts">
// Types
import type { Post } from "@/types/Strapi";

// i18n / Language
const localPath = useLocalePath();

const props = defineProps({
  attributes: {
    type: Object as PropType<Post>,
    required: true,
  },
});
</script>
