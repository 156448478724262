<template>
  <div
    ref="dropdown"
    :class="['dropdown-wrapper', { open: isOpen && isVisible }]"
  >
    <button
      class="dropdown-btn main"
      :class="{ exit: selectedCategory && isOpen }"
      @click="resetFilters"
    >
      <h4>CATEGORIES</h4>
      <svg
        class="remove-filter"
        viewBox="0 0 7 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.612 7C0.396 7 0.264 6.82 0.42 6.592L2.292 4C2.352 3.916 2.376 3.868 2.304 3.784L0.528 1.288C0.396 1.096 0.492 0.879999 0.72 0.879999H1.704C1.872 0.879999 1.932 0.927999 1.992 1.036L3.204 2.752C3.264 2.836 3.348 2.836 3.396 2.752L4.608 1.036C4.68 0.927999 4.74 0.879999 4.908 0.879999H5.88C6.108 0.879999 6.216 1.084 6.072 1.288L4.308 3.772C4.236 3.868 4.248 3.916 4.32 4L6.18 6.592C6.336 6.82 6.204 7 5.988 7H4.98C4.836 7 4.776 6.964 4.704 6.856L3.396 5.032C3.348 4.96 3.264 4.96 3.204 5.032L1.908 6.856C1.836 6.964 1.776 7 1.62 7H0.612Z"
          fill="black"
        />
      </svg>
    </button>
    <div class="dropdown-container">
      <template
        v-for="(filter, idx) in filters.data"
        :key="filter.id + '_' + idx"
      >
        <button
          v-if="filter.attributes?.slug"
          class="dropdown-btn"
          :class="{ active: selectedCategory === filter.attributes?.slug }"
          @click="showcategory(filter.attributes?.slug)"
        >
          <h5>{{ filter.attributes?.title }}</h5>
          <svg
            class="remove-filter"
            viewBox="0 0 7 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.612 7C0.396 7 0.264 6.82 0.42 6.592L2.292 4C2.352 3.916 2.376 3.868 2.304 3.784L0.528 1.288C0.396 1.096 0.492 0.879999 0.72 0.879999H1.704C1.872 0.879999 1.932 0.927999 1.992 1.036L3.204 2.752C3.264 2.836 3.348 2.836 3.396 2.752L4.608 1.036C4.68 0.927999 4.74 0.879999 4.908 0.879999H5.88C6.108 0.879999 6.216 1.084 6.072 1.288L4.308 3.772C4.236 3.868 4.248 3.916 4.32 4L6.18 6.592C6.336 6.82 6.204 7 5.988 7H4.98C4.836 7 4.776 6.964 4.704 6.856L3.396 5.032C3.348 4.96 3.264 4.96 3.204 5.032L1.908 6.856C1.836 6.964 1.776 7 1.62 7H0.612Z"
              fill="black"
            />
          </svg>
        </button>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
// Types
import type { PostCategoryEntityResponseCollection } from "@/types/Strapi";

const props = defineProps({
  filters: {
    type: Object as PropType<PostCategoryEntityResponseCollection>,
    required: true,
  },
  isVisible: {
    type: Boolean,
    required: true,
  },
});

// Filter posts
const dropdown = ref(null);
const isOpen = ref(false);
const selectedCategory = ref("");
const emits = defineEmits(["update:slug"]);

const showcategory = (slug: string) => {
  if (selectedCategory.value === slug) {
    selectedCategory.value = "";
  } else {
    selectedCategory.value = slug;
  }

  emits("update:slug", selectedCategory.value);
};

const resetFilters = () => {
  if (isOpen.value) {
    isOpen.value = false;
    selectedCategory.value = "";
    emits("update:slug", selectedCategory.value);
  } else {
    isOpen.value = true;
  }
};

onClickOutside(dropdown, () => {
  isOpen.value = false;
});

watch(
  () => props.isVisible,
  () => {
    !props.isVisible ? (isOpen.value = false) : null;
  },
);
</script>
