<template>
  <div>
    <HomeGallery />
    <AllPosts />
  </div>
</template>

<script setup lang="ts">
// Set meta tags with main ones from pinia
setMeta(true);
</script>
