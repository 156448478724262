import gql from "graphql-tag";

export const GET_HOMEPAGE = gql`
  query ($publicationState: PublicationState) {
    homeGalleryImages(
      sort: "rank:asc"
      publicationState: $publicationState
      pagination: { limit: 100 }
    ) {
      data {
        id
        attributes {
          rank
          image_desktop {
            data {
              id
              attributes {
                alternativeText
                caption
                mime
                url
              }
            }
          }
          image_mobile {
            data {
              id
              attributes {
                alternativeText
                caption
                mime
                url
              }
            }
          }
        }
      }
    }
  }
`;
