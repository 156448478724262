import gql from "graphql-tag";

export const GET_ALL_POSTS = gql`
  query ($locale: I18NLocaleCode!, $publicationState: PublicationState) {
    posts(
      locale: $locale
      sort: "rank:asc"
      publicationState: $publicationState
      pagination: { limit: 100 }
    ) {
      data {
        id
        attributes {
          rank
          title
          subtitle
          slug
          updatedAt
          image {
            data {
              id
              attributes {
                url
                alternativeText
                width
                height
              }
            }
          }
          category {
            data {
              id
              attributes {
                rank
                title
                slug
              }
            }
          }
        }
      }
    }
    postCategories(
      locale: $locale
      sort: "rank:asc"
      publicationState: $publicationState
      pagination: { limit: 100 }
    ) {
      data {
        id
        attributes {
          rank
          title
          slug
        }
      }
    }
  }
`;
